import { React , useState, useEffect } from 'react'
import { Row, Col, Accordion } from 'react-bootstrap'
import ButtonComponent from './ButtonComponent'
import { Link } from 'react-router-dom'

const PreguntasFrecuentesComp = () => {

  const [ respuesta, setRespuesta ] = useState('');
  const [ pregTextSelect, setPregTextSelect] = useState('');
  const [ pregId, setPregId ] = useState(0);

  
  const preguntasFrecuentes = [
    {
      pregunta: '¿Cuándo y para qué se realiza el Estado Parcelario?',
    },
    {
      pregunta: '¿Cuál es la vigencia de los Estados Parcelarios?',
    },
    {
      pregunta: 'El estado parcelario o cédula catastral incluye el amojonamiento de la parcela?',
    },
    {
      pregunta: '¿Qué se necesita para iniciar una subdivisión en Propiedad Horizontal?',
    },
    {
      pregunta: '¿Qué se necesita para iniciar un plano de usucapión?',
    },
  ]

  const elegirPregunta = ( paramId ) => {
    setPregId(paramId)
    setRespuesta(preguntasFrecuentes[paramId].respuesta)
    setPregTextSelect('preg__text--select')
  }

  useEffect(() => {
    elegirPregunta(0)
  }, []);
  
  return (
    
    <div className='section__container pb-5 mb-0' >
        <p className='bloque__titulo'> Si tenes dudas sobre que hace un agrimensor, duracion de partidas y todo lo referido a nuestra tarea aca tienes algunas aclaraciones  </p>
        <Row className='pt-5 mt-5'>
            <Col column="lg" lg={5} className='preg__desktop'>             
              <p className={ pregId === 0 ? 'preg__text ' + pregTextSelect : 'preg__text ' }  onClick={ () => elegirPregunta(0)}   style={{ cursor : "pointer"}} >  {preguntasFrecuentes[0].pregunta}</p>
              <hr />
              <p className={ pregId === 1 ? 'preg__text ' + pregTextSelect : 'preg__text ' } onClick={ () => elegirPregunta(1)} style={{ cursor : "pointer"}}> {preguntasFrecuentes[1].pregunta }</p>
              <hr />
              <p className={ pregId === 2 ? 'preg__text ' + pregTextSelect : 'preg__text ' } onClick={ () => elegirPregunta(2)} style={{ cursor : "pointer"}}> {preguntasFrecuentes[2].pregunta }</p>
              <hr />
              <p className={ pregId === 3 ? 'preg__text ' + pregTextSelect : 'preg__text ' } onClick={ () => elegirPregunta(3)} style={{ cursor : "pointer"}}> {preguntasFrecuentes[3].pregunta }</p>
              <hr />
              <p className={ pregId === 4 ? 'preg__text ' + pregTextSelect : 'preg__text ' } onClick={ () => elegirPregunta(4)} style={{ cursor : "pointer"}}> {preguntasFrecuentes[4].pregunta }</p>
              <hr />
              <div style={{ textAlign: 'center', paddingTop: '2rem'}}>
              </div>
            </Col>
            <Col column="lg" lg={7} className='preg__desktop'>
              <p className={ pregId === 0 ? 'section__text section__text--show'  : 'section__text section__text--hide' } >
                Se realiza previo a toda transmisión o modificación del dominio, siempre y cuando dicho estado parcelario no se encuentre vigente.
              </p>     
              <p className={ pregId === 1 ? 'section__text section__text--show'  : 'section__text section__text--hide' } >
                En parcelas urbanas baldías: 2 años (Art 15 Ley 10.707).<br/>
                En parcelas urbanas edificadas: 3 años (Disp. 6117/2015).<br/>
                En parcelas rurales: 3 años (Disp. 6117/2015).<br/>
                En sub parcelas PH, ubicadas en planta baja o planta superior que contengan superficie descubierta (terraza), también son 3 años por la Disp. 6117/2015..<br/>.<br/>
                Otra de las novedades que trajo la Disposición 6117/2015 es que las sub parcelas de ph, ubicadas en planta 1er piso o superiores como así también en subsuelo y cuyo plano origen sea anterior al año 1994, deberán constituir el estado parcelario correspondiente..<br/>.<br/>
                Como siempre, las sub parcelas, cocheras y bauleras que no contengan superficie descubierta, están exceptuadas de constituir el Estado Parcelario por la resolución 22/2012.
              </p>   
              <p className={ pregId === 2 ? 'section__text section__text--show'  : 'section__text section__text--hide' } >
                No. Se puede hacer, pero debe haberse solicitado para que se presupuesten adecuadamente. Son trabajos distintos.
              </p>   
              <p className={ pregId === 3 ? 'section__text section__text--show'  : 'section__text section__text--hide' } >
                Se necesita la escritura que certifique la titularidad sobre el bien, y fundamentalmente, el plano de obra aprobado por la Municipalidad competente.<br/><br/>
                La división en Propiedad Horizontal, puede realizarse en edificaciones construidas, en construcción y a construir (baldíos)
              </p> 
              <p className={ pregId === 4 ? 'section__text section__text--show'  : 'section__text section__text--hide' } >
                Para iniciar una mensura de este tipo, se necesita algún dato catastral de la propiedad (una copia de impuesto inmobiliario o municipal) y una copia del DNI de la o las personas que pretenden prescribir.-
              </p>        
            </Col>
        </Row>

        <Accordion defaultActiveKey="0" flush className='preg__mobile'>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{preguntasFrecuentes[0].pregunta}</Accordion.Header>
            <Accordion.Body>
              Se realiza previo a toda transmisión o modificación del dominio, siempre y cuando dicho estado parcelario no se encuentre vigente.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>{preguntasFrecuentes[1].pregunta}</Accordion.Header>
            <Accordion.Body>
              En parcelas urbanas baldías: 2 años (Art 15 Ley 10.707)<br/>
              En parcelas urbanas edificadas: 3 años (Disp. 6117/2015)<br/>
              En parcelas rurales: 3 años (Disp. 6117/2015)<br/>
              En sub parcelas PH, ubicadas en planta baja o planta superior que contengan superficie descubierta (terraza), también son 3 años por la Disp. 6117/2015.<br/><br/>
              Otra de las novedades que trajo la Disposición 6117/2015 es que las sub parcelas de ph, ubicadas en planta 1er piso o superiores como así también en subsuelo y cuyo plano origen sea anterior al año 1994, deberán constituir el estado parcelario correspondiente.<br/><br/>
              Como siempre, las sub parcelas, cocheras y bauleras que no contengan superficie descubierta, están exceptuadas de constituir el Estado Parcelario por la resolución 22/2012.<br/><br/>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>{preguntasFrecuentes[2].pregunta}</Accordion.Header>
            <Accordion.Body>
            No. Se puede hacer, pero debe haberse solicitado para que se presupuesten adecuadamente. Son trabajos distintos.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>{preguntasFrecuentes[3].pregunta}</Accordion.Header>
            <Accordion.Body>
              Se necesita la escritura que certifique la titularidad sobre el bien, y fundamentalmente, el plano de obra aprobado por la Municipalidad competente.<br/><br/>
              La división en Propiedad Horizontal, puede realizarse en edificaciones construidas, en construcción y a construir (baldíos)
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>{preguntasFrecuentes[4].pregunta}</Accordion.Header>
            <Accordion.Body>
            Para iniciar una mensura de este tipo, se necesita algún dato catastral de la propiedad (una copia de impuesto inmobiliario o municipal) y una copia del DNI de la o las personas que pretenden prescribir.-
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        
       
        
    </div>
    
  )
}

export default PreguntasFrecuentesComp