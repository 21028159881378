import './App.css';
import NavbarSite from '../src/components/common/NavbarSite.jsx'
import NavbarSiteLine from '../src/components/common/NavbarSiteLine.jsx'
import {Routes, Route, HashRouter } from 'react-router-dom';
import Home from './components/Home';
import Footer from '../src/components/Footer'

import Equipamiento from './components/sections/Equipamiento.jsx'
import Servicios from './components/sections/Servicios.jsx'
import PreguntasFrecuentes from './components/sections/PreguntasFrecuentes.jsx'
import QuienesSomos from './components/sections/QuienesSomos.jsx'
import Contacto from './components/sections/Contacto.jsx'
import Proyectos from './components/sections/Proyectos';

function App() {
  return (
    <div className="container-fluid p-0">
      <a href="https://api.whatsapp.com/send?phone=5492241558440&text=Hola,%20quisiera%20comunicarme%20para%20una%20consulta.." 
            className="float" target={'_blank'} rel="noreferrer"  style={{ cursor: 'pointer'}}>
          <i class="fab fa-whatsapp my-float"></i>
      </a>

      <HashRouter>
        <NavbarSite />
        <NavbarSiteLine />
        
          <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route exact path='/quienes-somos/' element={ <QuienesSomos/> } />
            <Route exact path='/equipamiento/' element={ <Equipamiento/> } />
            <Route exact path='/proyectos/' element={ <Proyectos/> } />
            <Route exact path='/preguntas-frecuentes/' element={ <PreguntasFrecuentes/> } />
            <Route exact path='/servicios/' element={ <Servicios/> } />
            <Route exact path='/contacto/' element={ <Contacto/> } />
            <Route path="*" element={<h2 className='text-center' style={{ paddingTop: 180, paddingBottom: 180}} >404 - Página no encontrada.</h2>}/>
          
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
