import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Header from '../common/Header'
import PreguntasFrecuentesComp from '../common/PreguntasFrecuentesComp'

const PreguntasFrecuentes = () => {
  return (
    <Container fluid className='p-0' >
      <Header urlImg='./images/headers/preguntas-frecuentes.jpg' titulo='Preguntas Frecuentes '/>
        <Container >
              <PreguntasFrecuentesComp className='pt-0'/>
         </Container>
    </Container>
  )
}

export default PreguntasFrecuentes