import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import LogoFooter from '../assets/logo-footer.svg'
import FooterFormulario from './common/FooterFormulario'

const Footer = () => {
  return (
    <Container className='footer__container' fluid>
        <Container className='pb-5'>
          <Row className='justify-content-lg-center'>
            <FooterFormulario />
            <hr className='mt-5 mb-5'/>
          </Row>
        </Container>
        <Row className='justify-content-lg-center'>
          <Col column="md" lg={2} className='footer__logo--position'>             
              <img src={LogoFooter} className='footer__logo' alt="Harispe Agrimensura" />
          </Col>
          <Col column="md" lg={4} className='pt-3 footer__contact'>
          
            <div className='footer__textBlock'>
              <span className='footer__icon'><i className="fa-solid fa-location-dot"></i></span>
              <span><p className='footer__text'> Independencia N 340,<br/>Lezama Provincia de Buenos Aires</p> </span>
            </div>
            <div className='footer__textBlock'>
              <span className='footer__icon'><i className="fa-solid fa-mobile-screen"></i></span>
              <span><p className='footer__text'> +54 9 2241 55-8440</p> </span>
            </div>
            
            <div className='footer__textBlock'>
              <span className='footer__icon'><i className="fa-solid fa-envelope"></i></span>
              <span><p className='footer__text'> <a href="mailto:harispe.agrimensura@gmail.com" className='footer__email'>harispe.agrimensura@gmail.com</a> </p> </span>
            </div>
          </Col>
          <Col column="md" lg={2} className='pt-3 footer__linkBlock'>             
              <p className='footer__title '>Accesos</p>
              <Link to={'/'}> <span><p className='footer__text footer__link'>Quienes somos</p> </span></Link>
              <Link to={'/'}> <span><p className='footer__text footer__link'>Equipamiento</p> </span></Link>
              <Link to={'/'}> <span><p className='footer__text footer__link'>Servicios </p> </span></Link>
              <Link to={'/'}> <span><p className='footer__text footer__link'>Contacto </p> </span></Link>
          </Col>
          <Col column="md" lg={2} className='pt-3 footer__linkBlock'>             
          <p className='footer__title '>Preguntas Frecuentes</p>
          <Link to={'/'}> <span><p className='footer__text footer__link'>Estado parcelario </p> </span></Link>
          <Link to={'/'}> <span><p className='footer__text footer__link'>Fotogrametría </p> </span></Link>
          <Link to={'/'}> <span><p className='footer__text footer__link'>Mensura </p> </span></Link>
          <Link to={'/'}> <span><p className='footer__text footer__link'>Propiedad horizontal </p> </span></Link>
          </Col>
        </Row>
    </Container>
  )
}

export default Footer